body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* hero video overlay */

.main {
  width: 100%;
  height: 100vh;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  /* background-color: #000000cc; */
}

.fontStyleText {
  font-family: "Montserrat";
  font-size: 16px;
}
.fontStyleButton {
  font-family: "Montserrat";
  font-size: 14px;
}
.fontStyleMainLinks {
  font-family: "Montserrat";
  font-size: 14px;
}
.fontStyleThingsToDo {
  font-family: "Montserrat";
  font-size: 14px;
  color: salmon;
}
.fontStyleThingsToDoText {
  font-family: "Montserrat";
  font-size: 14px;
}
.fontStyleWhatsIncluded {
  font-family: "Montserrat";
  font-size: 14px;
}
.fontStyleRooms {
  font-family: "Montserrat";
  font-size: 14px;
}
.fontStylefaq {
  font-family: "Montserrat";
  font-size: 14px;
}
.fontStylefooter {
  font-family: "Montserrat";
  font-size: 14px;
}

.fontStyleNav {
  font-family: "Montserrat";
  font-size: 18px;
  color: black;
}

.fontStyleVdrTitle {
  font-family: "Montserrat";
  font-size: 18px;
  color: black;
}
.fontStyleVdrList {
  font-family: "Montserrat";
  font-size: 15px;
  color: black;
}
.fontStyleIbpText {
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 550;
  color: black;
}
.ibpBackground {
  background: rgb(245, 245, 245, 0.6);
}

.tableHeader {
  background: #d92648;
  color: white;
}
